import Rails from "@rails/ujs"
import I18n from 'i18n-js/index.js.erb'
import 'bootstrap/js/dist/modal';
import validate from 'jquery-validation'
require("gasparesganga-jquery-loading-overlay")
if (document.documentElement.lang === "zh-HK") {
  require("jquery-validation/dist/localization/messages_zh_TW")
}
var $script = require("scriptjs");
import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');

document.addEventListener("turbolinks:load", function() {
  I18n.locale = $('body').data('locale');
  var next_payments_total_amount = 0;
  var any_amount = 0;
  var full_pay_required_amount = 0;
  var payment_amount_type = "current_outstanding";
  var payable_items = [];

  let select_payment_amount_type = (type, account_number_str) => {
    
    payment_amount_type = type;
    $("#payment_amount_type").val(type);
    if (type == "current_outstanding" || type == "remaining_account_outsanding_amount") {
      $("#payment_amount").attr("readonly", true);
    } else {
      $("#payment_amount").attr("readonly", false);
      $("#payment_amount").val(0);
    }
    $("#select_payment_amount_type_btn").text(I18n.t("payment.amount_choice." + type));
    var form = document.querySelector('form#get_payable_items_form');
    Rails.fire(form, 'submit');
  }

  let get_payable_item_title = (item) => {
    var title = "";
    if (item['repayment_record']['repayment_type'] == "repayment" && item['due_date']) {
      if (item['repayment_status'] == "settled") {
        title = I18n.t("payment.repayment_record_item.title.repayment", {tenor: item['repayment_record']['tenor']})
      } else {
        title = I18n.t("payment.repayment_record_item.title.partial_repayment", {tenor: item['repayment_record']['tenor']})
      }
    } else if (item['repayment_record']['repayment_type'] == "late_charge") {
      title = I18n.t("payment.repayment_record_item.title.late_charge", {tenor: item['repayment_record']['tenor']})
    } else if (item['repayment_record']['repayment_type'] == "transaction_fee") {
      title = I18n.t("payment.repayment_record_item.title.transaction_fee")
    }  else if (item['due_date'] == null) {
      title = I18n.t("payment.repayment_record_item.title.excess_repayment")
    }
    return title;
  };

  let get_payable_item_html = (item) => {
    var title = get_payable_item_title(item);
    var description = "";
    
    if (item['due_date']) {
      description = I18n.t("payment.repayment_record_item.description.with_due_date", {account_number: item['account_number'], date: item[
      'due_date']})
    } else if (item['repayment_record']['repayment_type'] == "transaction_fee") {
      description = ""
    } else {
      description = I18n.t("payment.repayment_record_item.description.without_due_date", {account_number: item['account_number']})
    }
    var amount = `HK$ ${item['repayment_record']['amount']}`;
    var item_html = `<tr class="border-bottom">
            <th class="pl-0 pt-0">${title}
              <div class="text-400 font-weight-normal fs--2">${description}</div>
            </th>
            <th class="pr-0 text-right pt-0">${amount}</th>
          </tr>`
     return item_html
  };

  let append_payable_items_table_html = (items) => {
    var payable_items_table = document.querySelector('#payable_items_table');
    while (payable_items_table.firstChild) {
      payable_items_table.firstChild.remove()
    }
    var payable_total = 0;
    var subtotal = 0;
    if (items.length > 0) {
      $("#payment_reminder_alert").hide()
      
      items.forEach((item, index) => {
        if (item['repayment_record']['repayment_type'] == "transaction_fee") {
          var subtotal_item_html = `<tr class="border-bottom">
                <th class="pl-0">${I18n.t("payment.repayment_record_item.title.subtotal")}</th>
                <th class="pr-0 text-right">HK$ ${subtotal.toFixed(2)}</th>
              </tr>`
          payable_items_table.insertAdjacentHTML('beforeend', subtotal_item_html);
          payable_items_table.insertAdjacentHTML('beforeend', get_payable_item_html(item))
          payable_total = subtotal + parseFloat(item['repayment_record']['amount'])
        } else {
          subtotal += parseFloat(item['repayment_record']['amount'])
          payable_items_table.insertAdjacentHTML('beforeend', get_payable_item_html(item))
        }
        
      })

      
      // var transaction_fee = subtotal * 0.0395 + 5;
      // var transaction_fee_item_html = `<tr class="border-bottom">
      //           <th class="pl-0">${I18n.t("payment.repayment_record_item.title.transaction_fee")}</th>
      //           <th class="pr-0 text-right">HK$ ${transaction_fee.toFixed(2)}</th>
      //         </tr>`
      // payable_items_table.insertAdjacentHTML('beforeend', transaction_fee_item_html);

      // var payable_total = subtotal + transaction_fee;
      var payable_total_amount_ele = document.querySelector('#payable_total_amount');
      payable_total_amount_ele.innerHTML = `HK$ ${payable_total.toFixed(2)}`
      $("#paymentSuccessModalBody").html(I18n.t("payment.confirmation.success.description", {amount: payable_total.toFixed(2), account_number: items[0].account_number}))
      var account_type = items[0].account_type
      var account_id = items[0].account_id
      if (account_type == "Application") {
        $("#paymentSuccessModalActionButton").prop("href", `/dashboard/accounts/${items[0].account_number}`)
      } else if (account_type == "CardInstalment") {
        $("#paymentSuccessModalActionButton").prop("href", `/dashboard/card_instalments/${account_id}`)
      }
      
    } else {
      $("#payment_reminder_alert").show()
    }
  }

  let create_paypal_order = (items) => {
    var subtotal = 0;
    var purchase_units = []
    items.forEach((item, index) => {
      subtotal += parseFloat(item['repayment_record']['amount'])
      var unit = {
        reference_id: item['repayment_record']['id'],
        amount: {
          value: parseFloat(item['repayment_record']['amount']).toFixed(2),
          currency_code: "HKD"
        },
        description: get_payable_item_title(item)
      }
      purchase_units.push(unit);
    })
    // var transaction_fee = subtotal * 0.0395 + 5;
    // var transaction_unit = {
    //     reference_id: "abcd",
    //     amount: {
    //       value: transaction_fee.toFixed(2),
    //       currency_code: "HKD"
    //     },
    //     description: I18n.t("payment.repayment_record_item.title.transaction_fee")
    //   }
    // purchase_units.push(transaction_unit)
    return purchase_units
  }

  $('#select_account_for_payment').on('change', function() {
    var form = document.querySelector('form#select_account_for_payment_form');
    Rails.fire(form, 'submit');
  });

  $(".select_payment_amount_type").on('click', (event) => {
    var payment_amount_type_val = event.target.attributes["payment_amount_type"].value;
    if (payment_amount_type_val == "current_outstanding") {
      $("#payment_amount").val(next_payments_total_amount);
    } else if (payment_amount_type_val == "remaining_account_outsanding_amount") {
      $("#payment_amount").val(full_pay_required_amount);
    }
    select_payment_amount_type(payment_amount_type_val);
  })

  $("#payment_amount").on("change", (event) => {
    var data = event.target.value
    if ($("#payment_amount_type").val() == "any_amount") {
      any_amount = data
    } else if ($("#payment_amount_type").val() == "remaining_account_outsanding_amount") {
    } else {
    }
    var form = document.querySelector('form#get_payable_items_form');
    Rails.fire(form, 'submit');
  })

  $("#select_account_for_payment_form").on("ajax:success", (event) => {
      console.log("success")
      var data = event.detail[0]
      next_payments_total_amount = data['next_payments_total_amount'];
      full_pay_required_amount = data['full_pay_required_amount']
      $("#payment_amount").val(data['next_payments_total_amount']);
      $("#selected_account").val(data['account_param'])
      select_payment_amount_type($("#payment_amount_type").val());
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });

  $("#get_payable_items_form").on("ajax:success", (event) => {
      console.log("success")
      payable_items = event.detail[0]
      console.log(`payable items: ${JSON.stringify(payable_items)}`)
      append_payable_items_table_html(payable_items);

  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
  var paypal_locale = "zh_HK";
  if (I18n.locale == "en") {
    paypal_locale = "zh_en"
  }
  // $script(`https://www.paypal.com/sdk/js?client-id=AXefG4K7KRe0RCAD-khLDVYx4ojJWeqB0zm0jwkLcI6j8V-Gqbu7ZcSuDhyZQNOjG_D6EqwgsKd5jNJs&currency=HKD&locale=${paypal_locale}&debug=true`, function() {
  $script(`https://www.paypal.com/sdk/js?client-id=AasOXHClO3keCmNGaCCOOoDcqgQHDcU04kW_xqDENCOkHU9E3fg8YsOLF--NdDWFsABkwfsw5WTajWjm&currency=HKD&locale=${paypal_locale}`, function() {
    paypal.Buttons({
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: create_paypal_order(payable_items)
        });
      },
      onApprove: function(data) {
        $.LoadingOverlay("show", {
            text        : I18n.t("payment.confirmation.loading_message")
        });
        return fetch('/payments/capture_paypal_transaction', {
            method: 'post',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              order_id: data.orderID,
              selected_account: $("#selected_account").val()
            })
          }).then(function(res) {
            return res.json();
          }).then(function(details) {
            // alert('Transaction approved by ' + details.payer_given_name);
            var status = details.table.result.table.status;
            if (status == "COMPLETED") {
              $("#paymentSuccessModal").modal('show');
            } else {
              $("#paymentFailedModal").modal('show');
            }
            $.LoadingOverlay("hide");

        })
      }
    }).render('#paypal-button-container')
  });

  
});

